import { Box, Typography } from "@material-ui/core"
import React from "react"
import Layout from "../components/layouts/MainLayout"
import SEO from "../components/utils/Seo"
import Icon from '@mdi/react'
import { mdiGoogleDownasaur } from '@mdi/js'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Box display="flex" justifyContent="center" style={{
      padding: 16
    }}>
      <Icon path={mdiGoogleDownasaur}
        style={{
          textAlign: 'center'
        }}
        title="Not found"
        size={3}
        color="black"
      />
    </Box>
    <Typography variant="h4" color="textPrimary" align="center">404: Not Found</Typography>
    <Typography variant="h6" color="textPrimary" align="center">Acabaste en una página que no existe... ¡qué tristeza!</Typography>
  </Layout>
)

export default NotFoundPage
